@use '@queries' as *;
@use '@fixed-size' as *;
@use '@content' as *;
@use '@typography' as *;
.empty {
  align-self: center;
  justify-self: center;
  padding: 6px 0;
}

.wrapper {
  min-height: 100dvh;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @include mainContentStyles;
  @include hideScrollbar;
  -webkit-overflow-scrolling: touch;

  > div {
    flex: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  overflow: visible;
  padding-bottom: 80px;
  @include hideScrollbar;
}

.item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @include min-744-break {
    grid-template-columns: repeat(4, 1fr);
  }
}

.footer {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 16px;

  .no-more-items {
    p {
      color: gray;
      @include typography-xs;
    }
  }
}

.card-skeleton {
  width: 100%;
  height: 100%;
  min-height: 314px;
  border-radius: var(--cornerradius12);

  @include min-428-break {
    min-height: 336px;
  }

  @include min-744-break {
    min-height: 320px;
  }
}
