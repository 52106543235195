@use '@queries' as *;
@use '@fixed-size' as *;
@use '@content' as *;
@use '@typography' as *;

.empty {
  align-self: center;
  justify-self: center;
  padding: 6px 0;
}

.wrapper {
  height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @include hideScrollbar;

  > div {
    flex: 1;
    overflow: auto;
    @include hideScrollbar;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  overflow: visible;
  padding-bottom: 80px;

  @include hideScrollbar;
}

.item {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @include min-1920-break {
    grid-template-columns: repeat(5, 1fr);
  }
}

.footer {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 16px;

  .no-more-items {
    p {
      color: gray;
      @include typography-xs;
    }
  }
}

.card-skeleton {
  @include fixedSize(100%, 307px);
  height: 100%;
  border-radius: var(--cornerradius12);

  @include min-1440-break {
    @include fixedSize(100%, 421px);
  }
}
